import API_DOMAIN from './apiDomain';
import * as user from './user';
import * as crud from './crud';
import * as schedule from './schedule';
import * as timeslots from './time-slots';
import * as reports from './reports';
import * as http from './http';

export default {
  API_DOMAIN,
  user,
  crud,
  schedule,
  http,
  timeslots,
  reports,
};

function handleCommonApiErrors(error) {
  const { response = {} } = error;
  const allowErrors = [400, 401, 403, 404];
  if (allowErrors.indexOf(response.status) !== -1) return;
  throw error;
}

export {
  API_DOMAIN,
  handleCommonApiErrors,
  user,
  crud,
  schedule,
  http,
  timeslots,
  reports,
};
