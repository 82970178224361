export const LOAD_DATA = 'LOAD_DATA';

export const loadDashboardData = (licenses, branches) => ({
  type: LOAD_DATA,
  payload: { licenses, branches },
});

const initialState = {
  licenses: [],
  branches: [],
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default dataReducer;
