/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import './SideMenu.scss';
import { Scrollbar } from 'react-scrollbars-custom';

/* eslint jsx-a11y/anchor-is-valid: "off" */

// 'permissionName 'icon', 'route', 'label': {}
export const menuItems = {
  student__customer__students__Customer: {
    students__Customers: {},
    'csr/new-registration/new__Register Customers': {},
    'customer-new-registration__New Registration': {},
    'transfer-students__Transfer Students': {},
    'refund-students__Refund Students': {},
    'customer-drop-off-registration__Drop Off Registration': {},
    'waiting-for-training__Customers Waiting For Assessment': {},
    'waiting-for-road-test__Customers Waiting For Road Test': {},
    'waiting-for-theory-test__Customers Waiting For Theory Test': {},
    'waiting-for-yard-test__Customers Waiting For Yard Test': {},
    'csr/rta-test-request__Rta Test Request': {},
    'csr/drop-off-registration__Drop Off Registration': {},
    'csr/collection-report__Collection Report': {},
    'training-students__Training Students': {},
    'customer-feedback__Customer feedback': {
      'customer-feedback/general-feedback__General Feedback': {},
      'customer-feedback/instance-wise-feedback__Instance wise Feedback': {},
    },
  },
  instructors__instructors__instructors__Instructors: {},
  'csr_smart_scheduler__csr__csr/dashboard__CSR & Smart Scheduler': {
    'csr/new-registration__New Registrations': {},
    'csr/lead-qualification__Lead Qualification': {},
    'csr/drop-off-registration__Drop Off Registration': {},
    'csr/rta-test-request__Rta Test Request': {},
    'csr/appointments__Appointments': {},

    'csr/external-leads__External Leads': {},
  },
  'cashier__cashier__cashier/dashboard__Cashier': {
    'cashier/dashboard__Dashboard': {},
    'cashier/dashboard/float-amount__Float Amount': {},
    'cashier/dashboard/collection-report__Collection Report': {},
    'cashier/dashboard/collect-payment__Collect Payment': {},
  },
  'salesman__cashier__salesman/dashboard__Salesman': {
    'salesman/dashboard__Dashboard': {},
    'salesman/dashboard/collection-report__Collection Report': {},
    'salesman/dashboard/collect-payment__Collect Payment': {},
    'salesman/dashboard/all-collection-report__All Collection Report': {},
  },
  'test_booking__booking__test_booking/dashboard__Test Booking': {
    // 'cashier/collect-payment__Collect Payment': {},
  },
  'supervisor_instructor__supervisor-instructor__supervisor-instructors__Supervisor & Instructor': {
    'supervisor/dashboard__Dashboard': {},
    'supervisor/stage-report__Customers': {},
    'supervisor/leave__Leave Summary': {},
    'supervisor/schedules__Schedules': {},
    'supervisor/pending-schedules__Pending Schedules': {},
    'supervisor/eco-cts-schedules__ECO CTS Schedules': {},
    'supervisor/upload-training-info__Update Training Info': {},
    'supervisor/change-instructor-of-schedules__Change Instructor Schedules': {},
    'supervisor/training-schedule__Training Schedule': {},
    'supervisor/absent-from-cts__Asbsent classes from CTS': {},
    'instructor-rating__Instructor Rating': {
      'instructor-rating/top-rated-instructors__Top Rated Instructors': {},
      'instructor-rating/customer-rating-of-instructor__Customer Ratings': {},
    },
    'supervisor/lecture-hall-schedule__Lecture Hall Schedule': {},
    'supervisor/mock-test-schedule__Mock Test Device Schedule': {},
    'supervisor/instructor-incentive__Instructor Incentive': {},
    'supervisor/instructor-incentive-processed__Instructor Incentive Processed': {},
    'supervisor/simulator-training-schedule__Simulator Training Schedule': {},
    'supervisor/training-lecture-hall-schedule__Training Lecture Hall Schedule': {},
  },
  corporate__corporate__corporate__Corporate: {},
  promotion__promotions__promotion__Promotions: {},
  'payment_report__payment-report__payment-reports__Payment Report': {},
  'admin_user__admin-user__admin-users__Admin User': {},
  'erp_user__admin-user__erp-users__Erp User': {},
  // 'bulk_schedule_theory_training__admin-user__bulk-schedule-theory-training__Bulk Schedule Theory Training': {},
  'training_course_schedule__booking__null__Training Schedule': {
    'training/bulk-schedule__Bulk Schedule': {},
    'training/schedule-listing__Schedule Listing': {},
  },
  'course_curriculum__course-curriculum__null__Course & Curriculum': {
    'course-rule-management__Course Rule Management': {},
    courses__Courses: {},
    'course-types__Course Types': {},
    'course-type-incentive__Course Type Incentive': {},
    'fee-codes__Fee Master': {},
    'lecture-theory__Theory Lectures': {},
    'lecture-hall__Lecture Hall': {},
    yards__Yards: {},
    'license-type__License Types': {},
    'working-hours__Working Hours': {
      'working-hours/practical-training__Practical Training': {},
      'working-hours/theory-training__Theory Training': {},
      'working-hours/mock-test__Mock Test': {},
      'working-hours/simulator-training__Simulator Training': {},
      'time-slot__Time Slot': {},
      'instructor-type__Instructor Type': {},
      'working-hours/training-theory-training__Training Course Theory Training': {},
    },
    'error-code__Error Code Manage': {
      'error-code-category__Error Code Category': {},
      'error-codes__Error Code': {},
    },
    instances__Instance: {},
    'training-lecture-theory__Training Theory Lectures': {},
  },
  'requests__requests__requests__Requests': {
    'additional-class-requests__Additional Class Requests': {},
  },
  'system_configuration__system-configuration__system-configurations__System Configuration': {
    'document-type__Document Type': {},
    'country-group__Country Group': {},
    'branch-types__Branch Types': {},
    'low-credit-settings__Low Credit Settings': {},
    branches__Branches: {},
    languages__Languages: {},
    'ris-languages__RIS Languages': {},
    'tax-classes__Tax Classes': {},
    'ramadan-settings__Ramadan Settings': {},
    'schedule-configurations__Schedule Configurations': {},
    holidays__Holidays: {},
    contract__Contract: {},
    'appointment-settings__Appointment Settings': {},
    'knowledge-base__Knowledge Base': {},
    'api-role-permissions__API Role Permissions': {},
    'api-permissions__API Permissions': {},
    'ticket-type-assignments__Ticket Type Assignments': {},
    'app-version-update__App Version Update': {},
    'preregistration-configurations__Pre-registration Configurations': {},
    'notification-settings__Notification Settings': {},
    'absenteesim-configurations__Absenteesim Configurations': {},
    'rta-test-addon-configurations__RTA Test Addon Configurations': {},
    'tabby-configurations__Tabby Configurations': {},
    'add-road-evaluation-configurations__Road Evaluation Configurations': {},
    'customer-feedback-configurations__Customer Feedback Configurations': {},
    'elite-cap-limit-configurations__Elite Cap Configurations': {},
  },

  'cms_templates__template__cms-templates__CMS Templates': {
    'email-templates__Email Templates': {},
    'notification-templates__Notification Templates': {},
    'sms-templates__Sms Templates': {},
  },

  'integrator__integrator-DI__integrator__Integrator DI': {
    'cts-sanityLog__SanityLog': {},
    'cts-StudentPermitDetails__Student Permit Details': {},
    'integrator-dashboard__Dashboard': {},
    'integrator-logs__Integrator Logs': {},
    'integrator-traininginfo__Training Info': {},
    'integrator-paymentclearance__Payment Clearance': {},
    'cts-studentprofileinfo__CTS Customer ProfileInfo': {},
    'cts-scheduleclass__CTS Schedule Class': {},
    'cts-lectureattendanceinfo__CTS Lecture Attendance Info': {},
    'cts-assessmentbooking__CTS Assessment Booking': {},
    'integrator-trainingdata__Training Data': {},
    'cts-assessmentresult__CTS Assessment Result': {},
    'cts-attendanceresult__CTS Attendance Result': {},
    'cts-instructoralert__CTS Instructor Alert': {},
    'di-studentcdd__Customer CDD': {},
    'di-examdetails__Exam Details': {},
    'di-examresult__Exam Result': {},
    'di-reschedulewarningmessage__Reschedule Warning': {},
    'rta-payment-info__RTA Payment Info': {},
    'cts-practicalattendanceinfo__CTS Practical Attendance Info': {},
  },
  'system_log__system-logs__system-logs__System Logs': {
    'additional-training-log__Additional Training Log': {},
    'download-history__Download History': {},
    'sms-log__Sms Log': {},
    'api-access-logs__API Access Logs': {},
    'utilization-log__Utilization Log': {},
    'student-utilization-log__Student Utilization Log': {},
    'change-training-branch-log__Change Training Branch Log': {},
    'invoice-erp-log__Invoice ERP Log': {},
  },
  // integrator__calendar__schedules__Schedules: {},
  report__reports__report__Reports: {
    'report/instructor-utilization__Instructor utilization Report': {},
    'report/registration__Registration Report': {},
    'report/test__Test Report': {},
    'report/mock/test__Mock Test Report': {},
    'report/instructor-api__Instructor API Report': {},
    'report/student__Student Report': {},
    'report/student/without-learning-permit-number__Student Without Learning Permit Number Report': {},
    'report/test-schedule__Test Schedule Report': {},
    'report/assessment-schedule__Assessment Schedule Report': {},
    'report/instructor__Instructor Report': {},
    'report/lecture-attendance__Lecture Attendance Report': {},
    'report/examiner-performance-report__Examiner Performance Report': {},
    'report/online-lecture-attendance__Online Lecture Attendance Report': {},
    'report/instructor-passing__Instructor Passing Report': {},
    'report/instructor-passing-performance__Overall Instructor Passing Performance Report': {},
    'report/first-attempt-passing-performance__First Attempt – Instructor Passing Performance Report': {},
    'report/internal-examiner-performance__Internal Examiner Performance Report': {},
    'report/upgraded-students__Upgraded Students Report': {},
    'report/zoho-report__Zoho Report': {},
    'report/pre-registration-report__Pre-Registration Report': {},
    'report/app-delete-request-report__App Delete Request Report': {},
    'report/golden-opportunity-report__Golden Opportunity Report': {},
    'report/student-feedback-report__Student Feedback Report': {},
    'report/student-utilization-report__Student Utilization Report': {},
    'report/expiry-date-report__Expiry Date Reports': {
      'expiry-date-report/lp-expiry-report__Learning Permit Expiry Report': {},
      'expiry-date-report/rta-file-expiry-report__RTA File Expiry Report': {},
    },
    'report/emaar-sales-report__EMAAR Sales Log Reports': {
      'emaar-sales-report/daily-sales-report__EMAAR Daily Sales': {},
      'emaar-sales-report/monthly-sales-report__EMAAR Monthly Sales': {},
    },
    'report/salesman-collection-report__Salesman Collection Report': {},
    'report/road-test-started-payment-due-report__Payment Due Report for Road Test Started': {},
    'report/road-test-passed-addon-due-report__Addon Due Report for Road Test Passed': {},
    'report/corporate-road-test-report__Corporate Road Test Reports': {
      'corporate-road-test-report/road-test-started-payment-due-report__Payment Due Report for Road Test Started': {},
      'corporate-road-test-report/road-test-passed-addon-due-report__Addon Due Report for Road Test Passed': {},
    },
    'report/elite-instructor-passing__Elite Instructor Passing Report': {},
    'report/elite-first-attempt-passing-performance__First Attempt – Elite Instructor Passing Performance Report': {},
    'report/stage-utilization-report__Stage Utilization Report': {},
    'report/elite-instructor-passing-performance__Overall Elite Instructor Passing Performance Report': {},
  },
  tickets__tickets__tickets__Tickets: {},
  transportation__transportation__transportation__Transportation: {
    'transportation/trip__Transportation Trips': {},
    'transportation/driver__Drivers': {},
    'transportation/bus__Bus': {},
    'transportation/vehicle-type__Vehicle Type': {},
    'transportation/vehicle-model__Vehicle Model': {},
    'transportation/locations__Locations': {},
    'transportation/routes-timings__Routes & Timings': {},
    'transportation/trip-holiday__Trip Holiday': {},
  },
  'referral_bonus__tickets__referral-bonus__Referral Bonus': {
    'referral-bonus__Referral Bonus List__Referral-bonus': {},
    'referral-bonus-configuration__Referral Bonus Configuration__Configure-Referral-Bonus': {},
    'referral-user-report__Referral User Report__Referral-bonus': {},
  },
  'utilization_report__tickets__utilization-report__Utilization Report': {
    'schedule-utilization-report__Schedule Utilization Report__Schedule-Utilization-Report': {},
    'instance-utilization-report__Instance Utilization Report__Instance-Utilization-Report': {},
  },
};
class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.isMenuOpen = this.isMenuOpen.bind(this);
    this.state = {
      showMenu: false,
      menuOpen: this.isMenuOpen(props),
    };
    this.menuToggle = null;
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMenuOpen = this.toggleMenuOpen.bind(this);
  }

  isMenuOpen(props) {
    return Object.keys(menuItems).find((menuKey) =>
      Object.keys(menuItems[menuKey])
        .map((menuItem) => {
          const itemInfo = menuItem.split('__');
          return props.location.pathname.includes(itemInfo[0]);
        })
        .find((item) => item)
    );
  }

  componentDidMount() {
    this.menuToggle = document.getElementById('menuToggle');
    this.menuToggle.addEventListener('click', this.toggleMenu);

    if (
      this.props.user &&
      this.props.user.userType === 'agent-user' &&
      menuItems.student__customer__students__Customer
    ) {
      delete menuItems.student__customer__students__Customer
        .students__Customers;
    } else {
      delete menuItems.student__customer__students__Customer[
        'csr/new-registration/new__Register Customers'
      ];
      delete menuItems.student__customer__students__Customer[
        'customer-drop-off-registration__Drop Off Registration'
      ];
    }
  }

  componentWillUnmount() {
    this.menuToggle.removeEventListener('click', this.toggleMenu);
  }

  toggleMenuOpen(menuOpen) {
    this.setState({ menuOpen, showMenu: menuOpen });
  }

  toggleMenu() {
    this.setState((state) => ({
      showMenu: !state.showMenu,
    }));
  }

  render() {
    const { adminUIPermissions = [], user } = this.props;

    const uiPermissions = adminUIPermissions.length
      ? adminUIPermissions.map((t) => t.key)
      : [];

    return (
      <>
        <div
          className={`sidemenu ${
            this.props.showMenu ?? this.state.showMenu ? 'active' : ''
          }`}
        >
          <Scrollbar noScrollX>
            <div className="toggle-menu-outer text-center bg-white position-sticky top-0 zIndex-9">
              <span
                className={`toggle-menu ${
                  this.props.showMenu ?? this.state.showMenu ? 'active' : ''
                }`}
                onClick={this.props.toggleMenu ?? this.toggleMenu}
                role="presentation"
              >
                <span className="bar" />
              </span>
            </div>
            <ul className={`menu ${this.state.menuOpen ? 'menu--open' : ''}`}>
              <li className="menu__item">
                <Link to="/" className="menu__link">
                  <span className="menu__icon icon-dashboard">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                  </span>
                  <span className="menu__text">Dashboards</span>
                </Link>
              </li>
              {Object.keys(menuItems)
                .filter((menuItem) => {
                  const itemInfo = menuItem.split('__');
                  return (
                    // permissionKeys.length === 0 ||
                    // permissionKeys.includes(itemInfo[0])
                    uiPermissions.length === 0 ||
                    uiPermissions.includes(itemInfo[0])
                  );
                })
                .map((menuItem) => {
                  const itemInfo = menuItem.split('__');
                  return (
                    <>
                      <MenuItemLevel1
                        showMenu={this.state.showMenu}
                        // key={this.props.location.pathname}
                        pathname={this.props.location.pathname}
                        toggleActive={this.toggleMenuOpen}
                        icon={itemInfo[1]}
                        link={itemInfo[2]}
                        label={itemInfo[3]}
                        childItems={menuItems[menuItem]}
                        key={itemInfo[2] + this.state.showMenu}
                        user={user}
                        adminUIPermissions={adminUIPermissions}
                        mainItem={itemInfo[0]}
                      />
                    </>
                  );
                })}
            </ul>
          </Scrollbar>
        </div>
        <div
          className={`black-drop menu-drop ${
            this.state.showMenu ? 'active' : ''
          }`}
          onClick={this.toggleMenu}
          role="presentation"
        />
      </>
    );
  }
}

SideMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({}),
  adminUIPermissions: PropTypes.arrayOf(PropTypes.shape({})),
};

SideMenu.defaultProps = {
  permissions: null,
  adminUIPermissions: [],
};

class MenuItemLevel1 extends React.Component {
  constructor(props) {
    super(props);
    this.toggleActive = this.toggleActive.bind(this);
    this.isActive = this.isActive.bind(this);
    this.thisElement = null;
    this.submenu = null;
    this.state = {
      pathname: props.pathname,
      active: this.isActive(props) && props.showMenu,
    };
  }

  isActive(props) {
    if (Object.keys(props.childItems).length > 0) {
      return Object.keys(props.childItems)
        .map((menuItem) => {
          const itemInfo = menuItem.split('__');
          return props.pathname.includes(itemInfo[0]);
        })
        .find((item) => item);
    }
    return props.pathname.includes(props.mainItem);
  }

  toggleActive(active) {
    this.setState({
      active,
    });
    this.props.toggleActive(active);
  }

  render() {
    const subMenuPermission = this.props.adminUIPermissions.find(
      (menu) => menu.key === this.props.mainItem
    );
    const subMenu = subMenuPermission ? subMenuPermission.subMenu : [];

    if (!Object.keys(this.props.childItems).length) {
      return (
        <li className="menu__item">
          <Link to={`/${this.props.link}`} className="menu__link">
            <i
              className={`menu__icon icon-${this.props.icon} ${
                this.isActive(this.props) ? ' active' : ''
              }`}
            />{' '}
            <span
              className={`menu__text ${
                this.isActive(this.props) ? ' active' : ''
              }`}
            >
              {this.props.label}
            </span>
          </Link>
        </li>
      );
    }
    const myMenuStyle = {
      padding: 0,
    };
    return (
      <li
        className={`menu__item ${
          this.state.active ? 'menu__item--active' : ''
        }`}
        ref={(element) => {
          this.thisElement = element;
        }}
      >
        {this.props.icon === 'csr' ? (
          <Link to={`/${this.props.link}`} className="menu__link">
            <i
              className={`menu__icon icon-${this.props.icon} ${
                this.isActive(this.props) ? ' active' : ''
              }`}
            />{' '}
            <span
              className={`menu__text${
                this.isActive(this.props) ? ' active' : ''
              }`}
            >
              {this.props.label}
            </span>
          </Link>
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <a
            className="menu__link"
            onClick={() => this.toggleActive(!this.state.active)}
          >
            <i
              className={`menu__icon icon-${this.props.icon}  ${
                this.isActive(this.props) ? ' active' : ''
              }`}
            />{' '}
            <span
              className={`menu__text${
                this.isActive(this.props) ? ' active' : ''
              }`}
            >
              {this.props.label}
            </span>
            <span
              className={`ml-auto ${this.state.active ? 'rotate-180' : ''}`}
            >
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none">
                <path
                  d="M11.1334 0.632571L11.1337 0.6329C11.2318 0.730801 11.2863 0.863776 11.2863 1.00468C11.2862 1.14605 11.2316 1.2812 11.1337 1.37892L11.1336 1.37904L6.06688 6.45489C6.06686 6.45491 6.06684 6.45493 6.06682 6.45495C5.963 6.55866 5.83092 6.6254 5.69729 6.6254L5.69534 6.6254C5.56021 6.6254 5.42818 6.55866 5.32452 6.45504C5.32447 6.455 5.32443 6.45496 5.32439 6.45492L0.253021 1.36531L0.252867 1.36516C0.0490446 1.16149 0.0490446 0.82947 0.252867 0.625803L0.252911 0.625758L0.625961 0.252524C0.625967 0.252518 0.625973 0.252512 0.625979 0.252506C0.724374 0.15412 0.855321 0.1 0.995803 0.1C1.13628 0.1 1.26713 0.154119 1.36543 0.252496L1.36546 0.252524L5.625 4.51207L5.69571 4.58278L5.76643 4.51207L10.021 0.257276C10.021 0.257267 10.0211 0.257257 10.0211 0.257247C10.1196 0.158799 10.2505 0.104633 10.3909 0.104633C10.5313 0.104633 10.662 0.15873 10.7605 0.257104C10.7606 0.257162 10.7606 0.257219 10.7607 0.257277L11.1334 0.632571Z"
                  fill="#0F1312"
                  stroke="white"
                  strokeWidth="0.2"
                />
              </svg>
            </span>
          </a>
        )}
        {this.state.active === true && (
          <div
            className="submenu"
            ref={(element) => {
              this.submenu = element;
            }}
          >
            <ul className="submenu__list">
              {Object.keys(this.props.childItems).map((menuItem) => {
                const itemInfo = menuItem.split('__');
                const active = this.state.pathname.includes(itemInfo[0]);

                return (
                  <>
                    <MenuItemLevel2
                      // active
                      // key={this.state.pathname}
                      pathname={this.state.pathname}
                      link={itemInfo[0]}
                      label={itemInfo[1]}
                      childItems={this.props.childItems[menuItem]}
                      key={itemInfo[0]}
                      subMenu={subMenu}
                    />
                  </>
                );
              })}
            </ul>
          </div>
        )}
      </li>
    );
  }
}

MenuItemLevel1.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  childItems: PropTypes.shape({}),
  pathname: PropTypes.string.isRequired,
};

MenuItemLevel1.defaultProps = {
  childItems: [],
};

class MenuItemLevel2 extends React.Component {
  constructor(props) {
    super(props);
    this.toggleActive = this.toggleActive.bind(this);
    this.thisElement = null;
  }

  componentDidMount() {}

  toggleActive() {
    const element = this.thisElement;
    if (element) {
      const classes = element.className.split(' ');
      const activeClass = classes.indexOf('menu__item--active');

      if (activeClass === -1) {
        element.className = classes.join(' ');
        element.className += ' menu__item--active';
      } else {
        classes.splice(activeClass, 1);
        element.className = classes.join(' ');
      }
    }
  }

  render() {
    const { link, subMenu } = this.props;
    const hasPermission = !!subMenu.find((me) => me.key === link);
    // return <li className="menu__item menu__item--submenu">{JSON.stringify(subMenu.map(p=>p.key)) + " : link: "+link } </li>;
    if (
      !Object.keys(this.props.childItems).length &&
      (!subMenu.length || hasPermission)
    ) {
      return (
        <li className="menu__item menu__item--submenu">
          <Link to={`/${this.props.link}`} className="menu__link">
            {this.props.label}
          </Link>
        </li>
      );
    }

    if (!subMenu.length || hasPermission) {
      return (
        <li
          className={`menu__item menu__item--submenu ${
            this.props.pathname.includes(this.props.link)
              ? ' menu__item--active'
              : ''
          }`}
          ref={(element) => (this.thisElement = element)}
        >
          <>
            <a
              className="menu__link"
              onClick={this.toggleActive}
              role="button"
              tabIndex="0"
            >
              {this.props.label}
              <span className="ml-auto">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none">
                  <path
                    d="M11.1334 0.632571L11.1337 0.6329C11.2318 0.730801 11.2863 0.863776 11.2863 1.00468C11.2862 1.14605 11.2316 1.2812 11.1337 1.37892L11.1336 1.37904L6.06688 6.45489C6.06686 6.45491 6.06684 6.45493 6.06682 6.45495C5.963 6.55866 5.83092 6.6254 5.69729 6.6254L5.69534 6.6254C5.56021 6.6254 5.42818 6.55866 5.32452 6.45504C5.32447 6.455 5.32443 6.45496 5.32439 6.45492L0.253021 1.36531L0.252867 1.36516C0.0490446 1.16149 0.0490446 0.82947 0.252867 0.625803L0.252911 0.625758L0.625961 0.252524C0.625967 0.252518 0.625973 0.252512 0.625979 0.252506C0.724374 0.15412 0.855321 0.1 0.995803 0.1C1.13628 0.1 1.26713 0.154119 1.36543 0.252496L1.36546 0.252524L5.625 4.51207L5.69571 4.58278L5.76643 4.51207L10.021 0.257276C10.021 0.257267 10.0211 0.257257 10.0211 0.257247C10.1196 0.158799 10.2505 0.104633 10.3909 0.104633C10.5313 0.104633 10.662 0.15873 10.7605 0.257104C10.7606 0.257162 10.7606 0.257219 10.7607 0.257277L11.1334 0.632571Z"
                    fill="#0F1312"
                    stroke="white"
                    strokeWidth="0.2"
                  />
                </svg>
              </span>
            </a>
            <ul className="submenu__items">
              {Object.keys(this.props.childItems).map((menuItem) => {
                const itemInfo = menuItem.split('__');
                return (
                  <MenuItemLevel3
                    pathname={this.props.pathname}
                    link={itemInfo[0]}
                    label={itemInfo[1]}
                    childItems={this.props.childItems[menuItem]}
                    key={itemInfo[0]}
                  />
                );
              })}
            </ul>
          </>
        </li>
      );
    }
    return <li className="menu__item menu__item--submenu" />;
  }
}

MenuItemLevel2.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  // /* eslint react/forbid-prop-types: off  */
  childItems: PropTypes.shape({}),
  pathname: PropTypes.string.isRequired,
};

MenuItemLevel2.defaultProps = {
  childItems: [],
};

const MenuItemLevel3 = (props) => {
  return (
    <li
      className={`menu__item menu__item--submenu-items ${
        props.pathname.includes(props.link) ? ' menu__item--active' : ''
      }`}
    >
      <Link to={`/${props.link}`} className="menu__link">
        {props.label}
      </Link>
    </li>
  );
};

MenuItemLevel3.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default withRouter((props) => <SideMenu {...props} />);
