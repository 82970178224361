import { toastr } from 'react-redux-toastr';
import { crud as crudApi, handleCommonApiErrors } from '../api';
import * as UserApi from '../api/user';

const resp = require('./response.json');

// action types
export const types = {
  GET_CDD_REQUEST: 'TRANSFER/GET_CDD_REQUEST',
  GET_CDD_SUCCESS: 'TRANSFER/GET_CDD_SUCCESS',
  GET_CDD_ERROR: 'TRANSFER/GET_CDD_ERROR',

  SHOW_SIGNUP_FORM_REQUEST: 'TRANSFER/SHOW_SIGNUP_FORM_REQUEST',
  SHOW_SIGNUP_FORM_SUCCESS: 'TRANSFER/SHOW_SIGNUP_FORM_SUCCESS',
  SHOW_SIGNUP_FORM_ERROR: 'TRANSFER/SHOW_SIGNUP_FORM_ERROR',

  GET_COURSE_REQUEST: 'TRANSFER/GET_COURSE_REQUEST',
  GET_COURSE_SUCCESS: 'TRANSFER/GET_COURSE_SUCCESS',
  GET_COURSE_ERROR: 'TRANSFER/GET_COURSE_ERROR',

  CRUD_LIST_REQUEST: 'TRANSFER/LIST_REQUEST',
  CRUD_LIST_SUCCESS: 'TRANSFER/LIST_SUCCESS',
  CRUD_LIST_ERROR: 'TRANSFER/LIST_ERROR',

  CRUD_READ_REQUEST: 'TRANSFER/READ_REQUEST',
  CRUD_READ_SUCCESS: 'TRANSFER/READ_SUCCESS',
  CRUD_READ_ERROR: 'TRANSFER/READ_ERROR',

  CRUD_UPDATE_REQUEST: 'TRANSFER/UPDATE_REQUEST',
  CRUD_UPDATE_SUCCESS: 'TRANSFER/UPDATE_SUCCESS',
  CRUD_UPDATE_ERROR: 'TRANSFER/UPDATE_ERROR',

  CRUD_DELETE_REQUEST: 'TRANSFER/DELETE_REQUEST',
  CRUD_DELETE_SUCCESS: 'TRANSFER/DELETE_SUCCESS',
  CRUD_DELETE_ERROR: 'TRANSFER/DELETE_ERROR',

  CRUD_SIGNUP_REQUEST: 'TRANSFER/SIGNUP_REQUEST',
  CRUD_SIGNUP_SUCCESS: 'TRANSFER/SIGNUP_SUCCESS',
  CRUD_SIGNUP_UPDATE_SUCCESS: 'TRANSFER/SIGNUP_UPDATE_SUCCESS',
  CRUD_SIGNUP_ERROR: 'TRANSFER/SIGNUP_ERROR',

  // course
  GET_COURSES_REQUEST: 'TRANSFER/GET_COURSES_REQUEST',
  GET_COURSES_SUCCESS: 'TRANSFER/GET_COURSES_SUCCESS',
  GET_COURSES_ERROR: 'TRANSFER/GET_COURSES_ERROR',
  COURSE_SELECTED: 'TRANSFER/COURSE_SELECTED',
  NEXT_SELECTED: 'TRANSFER/NEXT_SELECTED',

  CHOOSE_COURSE_REQUEST: 'TRANSFER/CHOOSE_COURSE_REQUEST',
  CHOOSE_COURSE_SUCCESS: 'TRANSFER/CHOOSE_COURSE_SUCCESS',
  CHOOSE_COURSE_ERROR: 'TRANSFER/CHOOSE_COURSE_ERROR',

  // payment plan
  CHANGE_PAYMENT_PLAN: 'TRANSFER/CHANGE_PAYMENT_PLAN',
  SELECT_MILESTONE: 'TRANSFER/SELECT_MILESTONE',
  CONTINUE_TO_PAYMENT: 'TRANSFER/CONTINUE_TO_PAYMENT',

  // payment
  PAYMENT_INITIATE_REQUEST: 'TRANSFER/PAYMENT_REQUEST',
  PAYMENT_INITIATE_SUCCESS: 'TRANSFER/PAYMENT_INITIATE_SUCCESS',
  PAYMENT_INITIATE_ERROR: 'TRANSFER/PAYMENT_INITIATE_ERROR',

  // payment
  PAYMENT_COMPLETE_REQUEST: 'TRANSFER/PAYMENT_COMPLETE_REQUEST',
  PAYMENT_COMPLETE_SUCCESS: 'TRANSFER/PAYMENT_COMPLETE_SUCCESS',
  PAYMENT_COMPLETE_ERROR: 'TRANSFER/PAYMENT_COMPLETE_ERROR',

  SELECT_PAYMENT_METHOD: 'TRANSFER/SELECT_PAYMENT_METHOD',

  CLEAR_DATA: 'TRANSFER/CLEAR_DATA',

  FETCH_UPDATED_REQUEST: 'AUTH/FETCH_UPDATED_REQUEST',
  FETCH_UPDATED_SUCCESS: 'AUTH/FETCH_UPDATED_SUCCESS',
  FETCH_UPDATED_ERROR: 'AUTH/FETCH_UPDATED_ERROR',

  INVOICE_SELECTED: 'TRANSFER/INVOICE_SELECTED',
  LUMPSUM_INVOICE_SELECTED: 'TRANSFER/LUMPSUM_INVOICE_SELECTED',
  // PROMOCODE
  PROMOCODE_INITIATE_REQUEST: 'TRANSFER/PROMOCODE_INITIATE_REQUEST',
  PROMOCODE_INITIATE_SUCCESS: 'TRANSFER/PROMOCODE_INITIATE_SUCCESS',
  PROMOCODE_INITIATE_ERROR: 'TRANSFER/PROMOCODE_INITIATE_ERROR',

  // PROMOCODE
  APPLY_PROMOCODE_INITIATE_REQUEST: 'TRANSFER/APPLY_PROMOCODE_INITIATE_REQUEST',
  APPLY_PROMOCODE_INITIATE_SUCCESS: 'TRANSFER/APPLY_PROMOCODE_INITIATE_SUCCESS',
  APPLY_PROMOCODE_INITIATE_ERROR: 'TRANSFER/APPLY_PROMOCODE_INITIATE_ERROR',
  APPLY_PROMOCODE_CLOSE: 'TRANSFER/APPLY_PROMOCODE_CLOSE',

  // EID
  READ_EID_REQUEST: 'TRANSFER/READ_EID_REQUEST',
  READ_EID_SUCCESS: 'TRANSFER/READ_EID_SUCCESS',
  READ_EID_ERROR: 'TRANSFER/READ_EID_ERROR',

  SAVE_UNSELECTED_INSTACE_REQUEST: 'TRANSFER/SAVE_UNSELECTED_INSTACE_REQUEST',

  UPDATE_TABBY_CHARGE: 'TRANSFER/UPDATE_TABBY_CHARGE',

  GET_TRANSFER_COURSE_REQUEST: 'TRANSFER/GET_TRANSFER_COURSE_REQUEST',
  GET_TRANSFER_COURSE_SUCCESS: 'TRANSFER/GET_TRANSFER_COURSE_SUCCESS',
  GET_TRANSFER_COURSE_ERROR: 'TRANSFER/GET_TRANSFER_COURSE_ERROR',
};

export const registrationStages = {
  registration: 'Registration',
  profileDetails: 'Profile Details',
  courseSelection: 'Course Selection',
  initiatePayment: 'Initiate Payment',
  completePayment: 'Complete Payment',
  finished: 'Finished',
};
export const originalRegistrationStages = {
  profileDetails: 'personalDetails',
  courseSelection: 'courseDetails',
  finished: 'homeScreen',
};

export const paymentModes = {
  online: 'Online',
  edsCenter: 'At ECO Center',
};

export const paymentPlans = {
  fullPayment: 'Full Payment',
  stagePayment: 'Stage Payment',
};

const summaryMapReduce = {
  initial: {
    amountRemaining: 0,
    noTaxableAmount: 0,
    // quantity: 0,
    roundOff: 0,
    taxableAmount: 0,
    totalAmount: 0,
    totalAmountPaid: 0,
    totalDiscount: 0,
    totalTax: 0,
    totalTaxPaid: 0,
    eds: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
    },
    rta: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
    },
  },
  reduce: (prev, current) => {
    return {
      amountRemaining: prev.amountRemaining + current.amount.amountRemaining,
      noTaxableAmount: prev.noTaxableAmount + current.amount.noTaxableAmount,
      // quantity: prev.quantity + current.amount.quantity,
      roundOff: prev.roundOff + current.amount.roundOff,
      taxableAmount: prev.taxableAmount + current.amount.taxableAmount,
      totalAmount: prev.totalAmount + current.amount.totalAmount,
      totalAmountPaid: prev.totalAmountPaid + current.amount.totalAmountPaid,
      totalDiscount: prev.totalDiscount + current.amount.totalDiscount,
      totalTax: prev.totalTax + current.amount.totalTax,
      totalTaxPaid: prev.totalTaxPaid + current.amount.totalTaxPaid,
      eds: {
        pricePerQuantity:
          prev.eds.pricePerQuantity + current.amount.eds.pricePerQuantity,
        taxPerQuantity:
          prev.eds.taxPerQuantity + current.amount.eds.taxPerQuantity,
        totalAmount: prev.eds.totalAmount + current.amount.eds.totalAmount,
        totalAmountPaid:
          prev.eds.totalAmountPaid + current.amount.eds.totalAmountPaid,
        totalDiscount:
          prev.eds.totalDiscount + current.amount.eds.totalDiscount,
        totalTax: prev.eds.totalTax + current.amount.eds.totalTax,
        totalTaxPaid: prev.eds.totalTaxPaid + current.amount.eds.totalTaxPaid,
      },
      rta: {
        pricePerQuantity:
          prev.rta.pricePerQuantity + current.amount.rta.pricePerQuantity,
        taxPerQuantity:
          prev.rta.taxPerQuantity + current.amount.rta.taxPerQuantity,
        totalAmount: prev.rta.totalAmount + current.amount.rta.totalAmount,
        totalAmountPaid:
          prev.rta.totalAmountPaid + current.amount.rta.totalAmountPaid,
        totalDiscount:
          prev.rta.totalDiscount + current.amount.rta.totalDiscount,
        totalTax: prev.rta.totalTax + current.amount.rta.totalTax,
        totalTaxPaid: prev.rta.totalTaxPaid + current.amount.rta.totalTaxPaid,
      },
    };
  },
};

// initial state
const initialState = {
  isLoading: false,
  isValidCdd: true,
  list: [],
  listCount: 0,
  record: {},
  isCloned: false,
  paymentMethod: 'cash',
  showCardInput: false,
  isPaymentError: false,
};

// reducer
// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    // CDD
    case types.GET_CDD_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
        CDDInfo: null,
        isValidCdd: false,
      };
    }
    case types.GET_CDD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        CDDInfo: action.data,
        trafficFileNo: action.trafficFileNo,
      };
    case types.GET_CDD_ERROR:
      return { ...state, isLoading: false, CDDInfo: null };

    // Sinup form

    case types.SHOW_SIGNUP_FORM_REQUEST: {
      return {
        ...initialState,
        isLoading: true,
      };
    }

    case types.SHOW_SIGNUP_FORM_SUCCESS: {
      return {
        ...initialState,
        isLoading: false,
        nextPage: action.data.nextPage,
      };
    }

    case types.GET_COURSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        courseForLicenseType: null,
        course: null,
      };
    case types.GET_COURSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courseForLicenseType: action.data,
        courses: [],
        hasCourse: false,
        course: null,
      };
    case types.GET_COURSE_ERROR:
      return { ...state, isLoading: false, courseForLicenseType: null };

    // list
    case types.CRUD_LIST_REQUEST: {
      const isNewList = action.currentPage === 1;
      if (isNewList) return { ...initialState, isLoading: true };
      return { ...state, isLoading: true };
    }
    case types.CRUD_LIST_SUCCESS: {
      const isNewList = action.currentPage === 1;
      return {
        isLoading: false,
        resource: action.resource,
        list: isNewList ? [...action.data] : [...state.list, ...action.data],
        listCount: action.count,
      };
    }

    case types.CRUD_LIST_ERROR:
      return { ...state, isLoading: false, list: [], listCount: null };

    // read
    case types.CRUD_READ_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_READ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: action.data,
      };

    case types.CRUD_READ_ERROR:
      return { ...state, record: {}, isLoading: false };

    // update
    case types.CRUD_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: action.data,
      };

    case types.CRUD_UPDATE_ERROR:
      return { ...state, isLoading: false };

    // delete
    case types.CRUD_DELETE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_DELETE_SUCCESS: {
      const indexOfRecord = state.list.indexOf(action.record);
      // console.log('indexOfRecord: ', indexOfRecord);
      const newListOfRecords = [...state.list];
      newListOfRecords.splice(indexOfRecord, 1);
      return {
        ...state,
        list: [...newListOfRecords],
        isLoading: false,
        record: {},
      };
    }
    case types.CRUD_DELETE_ERROR:
      return { ...state, isLoading: false };

    // clone
    case types.CRUD_CLONE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_CLONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCloned: true,
        record: action.data,
      };
    case types.CRUD_CLONE_ERROR:
      return { ...state, isLoading: false };

    // push
    case types.CRUD_PUSH_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_PUSH_SUCCESS: {
      const indexOfRecord = state.list.indexOf(action.record);
      // console.log('indexOfRecord: ', indexOfRecord);
      const newListOfRecords = [...state.list];
      newListOfRecords.splice(indexOfRecord, 1);
      return {
        ...state,
        list: [...newListOfRecords],
        isLoading: false,
        record: {},
      };
    }
    case types.CRUD_PUSH_ERROR:
      return { ...state, isLoading: false };

    // sign up
    case types.CRUD_SIGNUP_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // record: action.data,
        requiredLicenseId: action.requiredLicenseId,
        verify: action.data,
      };

    case types.CRUD_SIGNUP_ERROR:
      return { ...state, isLoading: false };
    case types.CRUD_SIGNUP_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // record: action.data,
        verify: {
          user: { ...action.data.userRecord, id: action.data.userRecord._id },
          token: action.token,
        },
        token: action.token,
        // hasCourse: false,
        // course: null,
        // courseType: null,
        isCompleted: false,
        blockIndex: 0,
        // currentStage: '',
      };

    // courses
    case types.GET_COURSES_REQUEST:
      return {
        ...state,
        isLoading: true,
        courses: null,
        selectedStages: null,
      };
    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courses: action.data.coursesWithFeeCodes,
        hasCourse: action.data.hasCourse,
        selectedStages: action.stages,
        selectedLumpSumCode: action.selectedLumpSumCode,
        isLumpSum: action.isLumpSum,
        isDirectTest: action.isDirectTest,
        isCategoryAddition: action.isCategoryAddition,
        isVipCourse: action.isVipCourse,
        roadTestFailed: action.roadTestFailed,
        roadTestFailedCount: action.roadTestFailedCount,
        addRoadEvaluation: action.addRoadEvaluation,
      };
    case types.GET_COURSES_ERROR:
      return { ...state, isLoading: false };

    case types.COURSE_SELECTED: {
      console.log('COURSE_SELECTED');
      // if (
      //   action.data.course === state.course &&
      //   action.data.courseType === state.courseType
      // ) {
      //   console.log('insidel if');
      //   return {
      //     ...state,
      //     isLoading: true,
      //     summary,
      //     currentStage: registrationStages.initiatePayment,
      //   };
      // }
      const course = formatCourse(action.data.course);
      const { courseType } = action.data;
      const { milestones } = course;
      milestones.sort((a, b) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount &&
        course.amount.promotions &&
        course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i += 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }

      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.initiatePayment,
        virtualTabbyPercentage: action.data?.virtualTabbyPercentage,
        otherTabbyPercentage: action.data?.otherTabbyPercentage,
      };
    }
    case types.CHOOSE_COURSE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.CHOOSE_COURSE_SUCCESS: {
      if (
        action.data.course === state.course &&
        action.data.courseType === state.courseType
      ) {
        return {
          ...state,
          isLoading: false,
          currentStage: registrationStages.courseSelected,
        };
      }
      const course = formatCourse(action.data.course);
      if (!course) {
        return {
          ...state,
          isLoading: false,
        };
      }
      const { courseType } = action.data;
      const { milestones } = course;
      milestones.sort((a, b) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount.promotions && course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i += 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      console.log('milestonesForStage', milestonesForStage);
      console.log('summary', summary);
      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.courseSelected,
        onlinePaymentInfo: undefined,
      };
    }
    case types.NEXT_SELECTED:
      return { ...state, blockIndex: action.data.index };

    case types.CHANGE_PAYMENT_PLAN: {
      const plan = action.data;
      if (plan === state.paymentPlan) {
        return state;
      }
      if (plan === paymentPlans.fullPayment) {
        return {
          ...state,
          summary: state.fullPayment.summary,
          paymentPlan: plan,
        };
      }
      if (plan === paymentPlans.stagePayment) {
        let { milestones } = state.stagePayment;
        const { course } = state;
        const zeroPaymentMilestone = milestones?.find(
          (m) => m.amount?.eds?.totalAmount + m.amount?.rta?.totalAmount === 0
        );
        const zeroPaymentMilestoneIndex = milestones?.indexOf(
          zeroPaymentMilestone
        );
        let summary;
        if (zeroPaymentMilestone && zeroPaymentMilestoneIndex === 0) {
          let courseMilestones = (course || { milestones: [] }).milestones;
          courseMilestones = courseMilestones.sort((a, b) => a.level - b.level);
          const nextMilestone = courseMilestones.find(
            (ml) => ml.level > zeroPaymentMilestone?.level
          );
          milestones = courseMilestones.filter(
            (cm) => cm.level <= nextMilestone.level
          );
          summary = getSummaryFromMilestones(milestones);
        }

        return {
          ...state,
          stagePayment: {
            ...state.stagePayment,
            milestones,
            summary: summary || state.stagePayment.summary,
          },
          summary: summary || state.stagePayment.summary,
          paymentPlan: plan,
        };
      }
      return state;
    }
    case types.SELECT_PAYMENT_METHOD: {
      const { paymentMethod } = action.data;
      const showCardInput =
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby';
      const showTabbyCardType = paymentMethod === 'tabby';

      return {
        ...state,
        paymentMethod,
        showCardInput,
        showTabbyCardType,
      };
    }
    case types.SELECT_MILESTONE: {
      if (state.paymentPlan !== paymentPlans.stagePayment) {
        return state;
      }
      const { milestone, selected } = action.data;
      let { milestones } = state.stagePayment;
      const { course } = state;

      let courseMilestones = (course || { milestones: [] }).milestones;
      courseMilestones = courseMilestones.sort((a, b) => a.level - b.level);
      const currIndex = milestones?.indexOf(milestone);
      const prevMilestone =
        currIndex > 0 ? milestones[currIndex - 1] : undefined;

      if (selected) {
        milestones = courseMilestones.filter(
          (cm) => cm.level <= milestone.level
        );
      } else if (
        prevMilestone &&
        currIndex === 1 &&
        (milestone.amount?.eds?.totalAmount +
          milestone.amount?.rta?.totalAmount ===
          0 ||
          prevMilestone.amount?.eds?.totalAmount +
            prevMilestone.amount?.rta?.totalAmount ===
            0)
      ) {
        milestones = courseMilestones.filter(
          (cm) => cm.level <= milestone.level
        );
      } else {
        milestones = courseMilestones.filter(
          (cm) => cm.level < milestone.level
        );
      }
      const nextIndex = milestones.length;

      for (let i = nextIndex; i < courseMilestones.length; i += 1) {
        const courseMilestone = courseMilestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestones.push(courseMilestone);
      }
      milestones = milestones.sort((a, b) => a.level - b.level);

      const summary = getSummaryFromMilestones(milestones);
      return {
        ...state,
        stagePayment: {
          ...state.stagePayment,
          milestones,
          summary,
        },
        summary,
      };
    }

    // payment
    case types.CONTINUE_TO_PAYMENT:
      return {
        ...state,
        currentStage: registrationStages.completePayment,
        paymentMethod: '',
        showCardInput: null,
        showTabbyCardType: null,
        tabbyCardType: null,
        tabbyCharge: 0,
        tabbyTax: 0,
      };
    case types.PAYMENT_INITIATE_REQUEST:
      return { ...state, isLoading: true };
    case types.PAYMENT_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentStage: registrationStages.finished,
        isCompleted: true,
        paymentDetails: action.data,
      };
    case types.PAYMENT_INITIATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isPaymentError: true,
        isPaymentCompleted: false,
      };
    case types.PAYMENT_COMPLETE_REQUEST:
      return { ...state, isLoading: true };
    case types.PAYMENT_COMPLETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentStage: registrationStages.finished,
        isPaymentCompleted: true,
        paymentHistoryId: action.data ? action.data.paymentHistoryId : null,
        paymentUserId:
          action.data && action.data.userCourseData
            ? action.data.userCourseData.user
            : null,
        paymentCourseId:
          action.data && action.data.userCourseData
            ? action.data.userCourseData._id
            : null,
        isLumpSumCourse:
          action.data && action.data.userCourseData
            ? action.data.userCourseData.courseType.isLumpSumCourse
            : false,
        isPaymentError: true,
      };
    case types.PAYMENT_COMPLETE_ERROR:
      return { ...state, isLoading: false, isPaymentCompleted: false };

    case types.CLEAR_DATA:
      return {
        // ...state,
        isPaymentError: false,
        isCompleted: false,
        isPaymentCompleted: false,
        record: null,
        isLoading: false,
        verify: null,
        hasCourse: false,
        course: null,
        courseType: null,
        promotion: null,
        stagePayment: null,
        fullPayment: null,
        paymentPlan: null,
        currentStage: '',
        blockIndex: 0,
        courses: null,
        hasPromotion: false,
        summary: null,
        totalAmount: 0,
        fullPaymentSelected: 0,
        totalForMilestones: 0,
        totalRtaForMilestones: 0,
        totalEdsForMilestones: 0,
        paymentMethod: '',
        milestones: [],
        token: null,
        payingAmount: 0,
        student: null,
        paymentHistoryId: null,
        step: 1,
        selectedStages: null,
        trafficFileNo: null,
        isDirectTest: false,
        isCategoryAddition: false,
        isLumpSum: false,
        selectedLumpSumCode: '',
        isVipCourse: false,
        roadTestFailed: false,
        roadTestFailedCount: 0,
        addRoadEvaluation: false,
      };

    case types.FETCH_UPDATED_REQUEST:
      return { ...state };

    case types.FETCH_UPDATED_SUCCESS:
      return {
        ...state,
        record: {
          userRecord: action.data,
        },
      };
    case types.FETCH_UPDATED_ERROR:
      return { ...state, isLoading: false };

    // promoCode
    case types.PROMOCODE_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        showPromoCodeBox: true,
      };
    case types.PROMOCODE_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: true,
        promotions: action.data,
      };
    case types.PROMOCODE_INITIATE_ERROR:
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: false,
      };

    // promoCode
    case types.APPLY_PROMOCODE_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        showPromoCodeBox: false,
      };
    case types.APPLY_PROMOCODE_CLOSE:
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: false,
      };
    case types.APPLY_PROMOCODE_INITIATE_SUCCESS: {
      const course = action.data;
      if (!course) {
        return {
          ...state,
          isLoading: false,
        };
      }
      const {
        courseType: {
          name: { en },
        },
      } = action.data;
      const { milestones } = course;
      milestones.sort((a, b) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount.promotions && course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i += 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType: en,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.courseSelected,
      };
    }
    case types.APPLY_PROMOCODE_INITIATE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.INVOICE_SELECTED:
      return { ...state, invoiceId: action.data.invoiceId, isLumpSum: false };

    case types.LUMPSUM_INVOICE_SELECTED:
      return {
        ...state,
        isLumpSum: true,
        invoiceId: action.data.courseId,
      };

    // EID
    case types.READ_EID_REQUEST:
      return { ...state, isLoading: true, record: null, eidData: null };
    case types.READ_EID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        record: action.data.record,
        eidData: action.data.eidData,
      };
    }
    case types.READ_EID_ERROR:
      return { ...state, isLoading: false };

    case types.SAVE_UNSELECTED_INSTACE_REQUEST:
      return {
        ...state,
        isLoading: false,
        stageUnSelectedInstanceIds: action.data.record,
      };
    case types.UPDATE_TABBY_CHARGE: {
      const { tabbyCharge } = action.data;
      const tabbyTax = (tabbyCharge * 5) / 100;
      return {
        ...state,
        tabbyCharge,
        tabbyTax,
      };
    }
    case types.GET_TRANSFER_COURSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        transferCourses: null,
      };
    case types.GET_TRANSFER_COURSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transferCourses: action.data,
      };
    case types.GET_TRANSFER_COURSE_ERROR:
      return { ...state, isLoading: false, transferCourses: null };

    default:
      return state;
  }
};
const formatCourse = (course) => {
  if (!course || !course.milestones) {
    return course;
  }
  return {
    ...course,
    milestones: course.milestones
      .map((ml) => {
        return {
          ...ml,
          stages: ml.instanceIds
            .map((stId) => course.stages.find((st) => st.stage._id === stId))
            .filter((sta) => sta !== undefined),
        };
      })
      .map((ml) => {
        return {
          ...ml,
        };
      }),
  };
};

const getSummaryFromMilestones = (milestones) => {
  return milestones.reduce(summaryMapReduce.reduce, summaryMapReduce.initial);
};

// action creators & async actions
export const actions = {
  getCourses: (request, data) => async (dispatch) => {
    const {
      isDirectTest,
      isCategoryAddition,
      roadTestFailed,
      roadTestFailedCount,
      addRoadEvaluation,
    } = data;
    dispatch({
      type: types.GET_COURSES_REQUEST,
      data: data.stages,
      isLumpSum: data.isLumpSum,
      selectedLumpSumCode: data.milestones,
      isVipCourse: data.isVipCourse,
    });
    try {
      const response = await crudApi.getCustomCourses(request, data);
      dispatch({
        type: types.GET_COURSES_SUCCESS,
        data: response.data,
        stages: data.stages,
        isLumpSum: data.isLumpSum,
        selectedLumpSumCode: data.milestones,
        isVipCourse: data.isVipCourse,
        isDirectTest,
        isCategoryAddition,
        roadTestFailed,
        roadTestFailedCount,
        addRoadEvaluation,
      });
    } catch (error) {
      dispatch({ type: types.GET_COURSES_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  showSignupForm: (request) => async (dispatch) => {
    dispatch({
      type: types.SHOW_SIGNUP_FORM_REQUEST,
      currentPage: 1,
    });
    try {
      dispatch({
        type: types.SHOW_SIGNUP_FORM_SUCCESS,
        data: { nextPage: 'signup', step: 2 },
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.GET_CDD_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  getCourse: (request) => async (dispatch) => {
    dispatch({
      type: types.GET_COURSE_REQUEST,
    });
    try {
      const response = await crudApi.getCourseStructure(request);

      dispatch({
        type: types.GET_COURSE_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.GET_COURSE_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  getCDDInfo: (request, trafficFileNo) => async (dispatch) => {
    dispatch({
      type: types.GET_CDD_REQUEST,
      currentPage: 1,
    });
    try {
      // const response = await crudApi.getFullListCDD(request);
      const response = {
        data: resp,
      };
      dispatch({
        type: types.GET_CDD_SUCCESS,
        data: response.data,
        trafficFileNo,
        currentPage: 1,
      });
      console.log('dispatch GET_CDD_SUCCESS');
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.GET_CDD_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  get: (request) => async (dispatch) => {
    dispatch({ type: types.CRUD_READ_REQUEST });
    try {
      const response = await crudApi.get(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_READ_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.CRUD_READ_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  getVerifiedToken: (request) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const response = await crudApi.get(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_SIGNUP_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  create: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_READ_REQUEST });
    try {
      const response = await crudApi.create(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_READ_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_READ_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  update: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.update(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  updateByToken: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.updateByToken(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      // toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  remove: (request, record) => async (dispatch) => {
    dispatch({ type: types.CRUD_DELETE_REQUEST });
    try {
      await crudApi.remove(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_DELETE_SUCCESS,
        record,
      });
      toastr.success('Success', 'Delete successful!');
    } catch (error) {
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  signUp: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const requiredLicenseId = null;
      const response = await crudApi.create(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_SIGNUP_SUCCESS,
        data: response.data,
        requiredLicenseId,
      });
      // toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },
  signUpUpdate: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const response = await crudApi.createWithId(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_SIGNUP_UPDATE_SUCCESS,
        data: response.data,
        token: request.token,
      });
      // toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },
  selectCourse: (
    course,
    courseType,
    token,
    stages,
    licenseTypeId,
    userId,
    trafficFileNo,
    isDirectTest,
    isCategoryAddition,
    isLumpSum,
    selectedLumpSumCode,
    isVipCourse = false,
    roadTestFailed,
    roadTestFailedCount,
    transferCourseType,
    addRoadEvaluation
  ) => async (dispatch) => {
    dispatch({ type: types.CHOOSE_COURSE_REQUEST });
    try {
      if (!isLumpSum) {
        const data = {
          stages,
          licenseTypeId,
          userId,
          preApplyPromotion: true,
          courseTypeId: course.courseType._id,
          trafficFileNo,
          isDirectTest,
          isCategoryAddition,
          roadTestFailed,
          roadTestFailedCount,
          transferCourseType,
        };
        const response = await crudApi.chooseCustomCourse(data, token);
      } else {
        const data = {
          stages,
          licenseTypeId,
          userId,
          preApplyPromotion: true,
          courseTypeId: course.courseType._id,
          milestones: selectedLumpSumCode,
          trafficFileNo,
          isDirectTest,
          isCategoryAddition,
          isVipCourse,
          roadTestFailed,
          roadTestFailedCount,
          transferCourseType,
          addRoadEvaluation,
        };
        const response = await crudApi.chooseCustomLumpSumCourse(data, token);
      }

      const tabbyConfigurationResponse = await crudApi.getTabbyConfiguration();

      dispatch({
        type: types.COURSE_SELECTED,
        data: {
          course,
          courseType,
          virtualTabbyPercentage:
            tabbyConfigurationResponse?.virtualTabbyPercentage ?? 4.71,
          otherTabbyPercentage:
            tabbyConfigurationResponse?.otherTabbyPercentage ?? 2.71,
        },
      });
    } catch (error) {
      dispatch({ type: types.CHOOSE_COURSE_ERROR });
    }
  },
  previewCourse: (course, courseType) => async (dispatch) => {
    dispatch({ type: types.CHOOSE_COURSE_REQUEST });
    try {
      dispatch({
        type: types.COURSE_SELECTED,
        data: { course, courseType },
      });
    } catch (error) {
      dispatch({ type: types.CHOOSE_COURSE_ERROR });
    }
  },
  nextBlock: (index) => async (dispatch) => {
    dispatch({
      type: types.NEXT_SELECTED,
      data: { index },
    });
  },
  changePaymentPlan: (plan) => (dispatch) => {
    dispatch({
      type: types.CHANGE_PAYMENT_PLAN,
      data: plan,
    });
  },
  continueToPayment: () => (dispatch) => {
    dispatch({
      type: types.CONTINUE_TO_PAYMENT,
    });
  },
  selectPaymentMethod: (paymentMethod) => (dispatch) => {
    dispatch({
      type: types.SELECT_PAYMENT_METHOD,
      data: { paymentMethod },
    });
  },
  selectMilestone: (milestone, selected) => (dispatch) => {
    dispatch({
      type: types.SELECT_MILESTONE,
      data: { milestone, selected },
    });
  },
  clearData: () => (dispatch) => {
    dispatch({
      type: types.CLEAR_DATA,
    });
  },
  fetchUpdatedRecord: (request) => async (dispatch) => {
    dispatch({ type: types.FETCH_UPDATED_REQUEST });
    try {
      const response = await UserApi.getStudentProfile(request);
      const { data } = response;
      dispatch({ type: types.FETCH_UPDATED_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.FETCH_UPDATED_ERROR });
      handleCommonApiErrors(error);
    }
  },
  initiatePayment: (
    milestones,
    totalAmount,
    courseType,
    promotion,
    token,
    paymentMethod,
    createdBy,
    bank,
    card,
    last4,
    tabbyCardType
  ) => async (dispatch) => {
    dispatch({ type: types.PAYMENT_INITIATE_REQUEST });
    try {
      const cardDetails =
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby'
          ? {
              bank,
              card,
              last4,
            }
          : undefined;

      if (paymentMethod === 'tabby') {
        cardDetails.tabbyCardType = tabbyCardType;
      }

      if (
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby'
      ) {
        if (paymentMethod === 'tabby' && !tabbyCardType) {
          dispatch({ type: types.PAYMENT_INITIATE_ERROR });
          toastr.error('Error', 'Please select tabby card type');
          return;
        }

        if (card === '' || bank === '' || last4.length !== 4) {
          dispatch({ type: types.PAYMENT_INITIATE_ERROR });
          if (last4.length !== 4 && last4.length !== 0) {
            toastr.error('Error', 'Card number must be 4 digits ');
          } else {
            toastr.error('Error', 'Please select card details');
          }
          return;
        }
      }
      const paymentType = paymentMethod || 'cash';
      const data = {
        milestones,
        amount: totalAmount,
        courseType,
        promotionId: promotion != null ? promotion._id : null,
        isCsrPayment: true,
        paymentMethod: paymentType,
        createdBy,
        cardDetails,
        platform: 'csr',
      };

      if (paymentMethod === 'tabby') {
        const cardData = {
          cardDetails,
        };
        await crudApi.updateTabbyDetails(cardData, token);
      } else {
        await crudApi.updateNonTabbyDetails({}, token);
      }

      dispatch({ type: types.PAYMENT_INITIATE_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.PAYMENT_INITIATE_ERROR });
      toastr.error('Error', error.response.data.message);
      handleCommonApiErrors(error);
    }
  },
  completePayment: (
    milestones,
    totalAmount,
    courseType,
    promotion,
    token,
    paymentMethod,
    createdBy,
    bank,
    card,
    last4,
    stageUnSelectedInstanceIds,
    tabbyCardType
  ) => async (dispatch) => {
    dispatch({ type: types.PAYMENT_COMPLETE_REQUEST });
    try {
      const cardDetails =
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby'
          ? {
              bank,
              card,
              last4,
            }
          : undefined;

      if (paymentMethod === 'tabby') {
        cardDetails.tabbyCardType = tabbyCardType;
      }

      if (
        paymentMethod === 'card' ||
        paymentMethod === 'link' ||
        paymentMethod === 'tabby'
      ) {
        if (paymentMethod === 'tabby' && !tabbyCardType) {
          dispatch({ type: types.PAYMENT_INITIATE_ERROR });
          toastr.error('Error', 'Please select tabby card type');
          return;
        }

        if (!bank || !card || !last4 || last4.length !== 4) {
          dispatch({ type: types.PAYMENT_INITIATE_ERROR });

          if (last4.length !== 4 && last4.length !== 0) {
            toastr.error('Error', 'Card number must be 4 digits ');
          } else {
            toastr.error('Error', 'Please select card details');
          }
          return;
        }
      }
      const paymentType = paymentMethod || 'cash';
      const data = {
        milestones,
        amount: totalAmount,
        courseType,
        promotionId: promotion != null ? promotion._id : null,
        isCsrPayment: true,
        paymentMethod: paymentType,
        createdBy,
        cardDetails,
        isTransferStudent: true,
        platform: 'csr',
        stageUnSelectedInstanceIds,
      };

      const response = await crudApi.initiatePayment(token, data);

      dispatch({ type: types.PAYMENT_COMPLETE_SUCCESS, data: response.data });
    } catch (error) {
      toastr.error('Error', error.response.data.message);
      dispatch({ type: types.PAYMENT_COMPLETE_ERROR });
      handleCommonApiErrors(error);
    }
  },
  showInvoiceBlock: (invoiceId) => async (dispatch) => {
    dispatch({
      type: types.INVOICE_SELECTED,
      data: { invoiceId },
    });
  },
  showLumpSumInvoiceBlock: (courseId) => async (dispatch) => {
    dispatch({
      type: types.LUMPSUM_INVOICE_SELECTED,
      data: { courseId },
    });
  },
  clearPromoCode: (token, course) => async (dispatch) => {
    dispatch({ type: types.PROMOCODE_INITIATE_REQUEST });
    try {
      const data = {
        token,
        courseId: course && course.course ? course.course._id : '',
      };
      const response = await crudApi.getPromotions(data);

      dispatch({ type: types.PROMOCODE_INITIATE_SUCCESS, data: response.data });
    } catch (error) {
      dispatch({ type: types.PROMOCODE_INITIATE_ERROR });

      handleCommonApiErrors(error);
    }
  },
  applyPromoCode: (promoCode, token) => async (dispatch) => {
    dispatch({ type: types.APPLY_PROMOCODE_INITIATE_REQUEST });
    try {
      if (!promoCode) {
        dispatch({ type: types.APPLY_PROMOCODE_CLOSE });
      } else {
        const data = { promoCode };
        const response = await crudApi.applyPromotion(data, token);
        dispatch({
          type: types.APPLY_PROMOCODE_INITIATE_SUCCESS,
          data: response.data.userCourseData,
        });
      }
    } catch (error) {
      dispatch({ type: types.APPLY_PROMOCODE_INITIATE_ERROR });

      toastr.error('Error', error.response.data.message);
    }
  },
  readEid: (record, eidData) => async (dispatch) => {
    dispatch({ type: types.READ_EID_REQUEST });
    try {
      dispatch({
        type: types.READ_EID_SUCCESS,
        data: { record, eidData },
      });
    } catch (error) {
      dispatch({ type: types.READ_EID_ERROR });
      handleCommonApiErrors(error);
    }
  },
  saveUnselectedInstanceIds: (data) => async (dispatch) => {
    dispatch({
      type: types.SAVE_UNSELECTED_INSTACE_REQUEST,
      data: { record: data.stageUnSelectedInstanceIds },
    });
  },
  updateTabbyCharge: (tabbyCharge) => (dispatch) => {
    dispatch({
      type: types.UPDATE_TABBY_CHARGE,
      data: { tabbyCharge },
    });
  },
  getTransferCourses: (request) => async (dispatch) => {
    dispatch({
      type: types.GET_TRANSFER_COURSE_REQUEST,
    });
    try {
      const response = await crudApi.getCourseStructure(request);
      dispatch({
        type: types.GET_TRANSFER_COURSE_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.GET_TRANSFER_COURSE_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },
};

export function serializeAndShowFormErrors(error) {
  if (error.response && error.response.data && error.response.data.errors) {
    const { errors } = error.response.data;
    Object.keys(errors).forEach((e) => {
      // console.log('error: ', e, errors[e].message);
      if (errors[e].errorMessage) {
        toastr.error(errors[e].errorMessage);
      } else {
        toastr.error(errors[e].message);
      }
    });
    return;
  }
  if (error.response && error.response.data && error.response.data.message) {
    toastr.error(error.response.data.message);

    return;
  }
  toastr.error('Error', 'Invalid Data in Form!');
}
