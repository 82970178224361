/* eslint-disable class-methods-use-this */

import io from 'socket.io-client';
import API_DOMAIN from './api/apiDomain';
import { store } from './store';
import { actions } from './store/notification';
import notificationEvents from './notification-events';

class NotificationSocket {
  constructor() {
    this.socket = null;
  }

  connect() {
    this.socket = io(`${API_DOMAIN}`, { path: '/notification/connect' });
    this.socket.on('connect', () => {
      console.log(`socket connected ${this.socket.id}`);
    });
    this.socket.on('disconnect', () => {
      console.log('socket disconnected');
    });
    this.socket.on(
      'notification:ack_with_user_id',
      this.handleSendAck.bind(this)
    );
  }

  handleSendAck(incomingData) {
    console.log('incomingData: ', incomingData);
    const userId = store.getState().authUser.user.id;
    const { callEvent, notificationId } = incomingData;
    const dataToSend = { notificationId, userId };
    console.log('incomingData: ', dataToSend);

    this.socket.emit(callEvent, dataToSend, this.handleAckResponse.bind(this));
  }

  handleAckResponse(response) {
    // console.log(response);
    if (response.status === 'something_new') {
      const { notification } = response;
      this.showMessage(notification);
      this.processEvent(notification);
    }
    if (response.status === 'something_ill') {
      console.error(response.message);
    }
  }

  showMessage(notification) {
    if (!notification.message) return;
    return store.dispatch(actions.pushNew(notification));
  }

  processEvent(notification) {
    const { event = undefined } = notification;
    if (!event) return;
    const eventHandler = notificationEvents[event];
    // if (typeof eventHandler !== 'function') {
    //   throw Error(`Notification Event Handler Not found for ${event}`);
    // }
    if (typeof eventHandler === 'function') {
      eventHandler.call(null, notification);
    }
  }
}

const notificationSocket = new NotificationSocket();

export default notificationSocket;
